export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

export const appVersion: string = "2.0.0-feature-ST-2789-update-to-pnpm.22";
export const appTimestamp: string = "2025-04-03T10:50:06.000Z";
export const commitId: string = "d041ca11543b9ac5368a5cfd7c1e0164589fcf44";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/commit/d041ca11543b9ac5368a5cfd7c1e0164589fcf44?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "32172";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/EntryPage/pullrequest/32172";
export const jiraTicketId: string = "ST-2789";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-2789";

export const localizeKey = "iKNI0OJMw3RLe";
export const sentryDSNKey =
  "https://20e8bee1014c8ffa9f8b67f3d69a9709@o381590.ingest.sentry.io/4505912288411648";

export const isNumberedEnv = isHostNumberedEnv(window.location.host);
export const numberedEnv = isNumberedEnv
  ? getNumberedEnv(window.location.host)
  : null;
